import React, { Component } from 'react';

class CommentsList extends Component {

  render() {
    const {comments} = this.props;

    return (

        <div class="list-group ">
        {
          comments.map((item, idx) => (
              <div class="list-group-item list-group-item-action flex-column align-items-start shadow-sm mt-1" key={idx}>
              <div class="row">
              <div class="col-8">{item.date && item.date.substring(0, 19).replace('T', ' ') }</div>
              <div class="col-2"></div>
              <div class="col-2">{item.userId}</div>
              </div>
              <div class="row">
              <div class="col-12">{item.comment}</div>
              </div>

            </div>
          ))
        }
      </div>
    )
  }
}

export default CommentsList;
