
//---------------------------
//      Object.assign({id: 'Z-100', date: '2019-01-25T00:28:49.848Z', cmd: cmd,}, args));

export async function postCmd (cmd, args) {

  const postUrl = 'https://api.whazonk.com/cmd'

  if(! args.hasOwnProperty("date")) {
    args = Object.assign(args, {date: '2019-01-25T00:28:49.848Z'});
  }

  try {
    let resp = await postData(
      postUrl,
      Object.assign({
        id: 'Z-100',
        cmd: cmd,
      }, args));

    return resp;

  } catch(err) {
    console.log(err);
  }
}


/*

export async function postCmd (cmd, prop, value) {

  const postUrl = 'https://api.whazonk.com/cmd'

  try {
    let resp = await postData(postUrl, {
      id: 'Z-100',
      date: '2019-01-25T00:28:49.848Z',
      cmd: cmd,
      arg1: prop,
      arg2: value,
      notif: {p1: 'val-p1.2', p2: 'val-p2'},
      prefs: ['arg1','arg2','arg3']
//      arg3: 'val for arg3',
//      arg4: 'updated xx-arg-4.2',
      //      opt: '32'
//      arg5: null
    }
                             );
    return resp;

  } catch(err) {
    console.log(err);
  }
}

*/



/*
  postData(`http://example.com/answer`, {answer: 42})
    .then(data => console.log(JSON.stringify(data))) // JSON-string from `response.json()` call
    .catch(error => console.error(error));
*/


function postData(url = ``, data = {}) {

  return fetch(url, {
    method: "POST",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin",

    redirect: "follow",
    referrer: "no-referrer",

    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },

    body: JSON.stringify(data),
  })
    .then(response => response.json()); // parses response to JSON
}



export function baseURL () {
//  return 'https://zone1.whazonk.com/data';
  return 'https://api.whazonk.com/data2';
}
