import { connect } from 'react-redux'
import { toggleAttend, sendAttend } from '../actions'
import AttendList from '../components/AttendList'
import { VisibilityFilters } from '../actions'

const getVisibleAttend = (attends, filter) => {
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return attends
    case VisibilityFilters.SHOW_COMPLETED:
      return attends.filter(t => t.completed)
    case VisibilityFilters.SHOW_ACTIVE:
      return attends.filter(t => !t.completed)
    default:
      throw new Error('Unknown filter: ' + filter)
  }
}

const mapStateToProps = state => ({
  attends: getVisibleAttend(state.attends, state.visibilityFilter)
})

const mapDispatchToProps = dispatch => ({
  toggleAttend: id => dispatch(toggleAttend(id)),
  sendAttend: id => dispatch(sendAttend(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendList)
