import React, { Component } from 'react';


//import { Link } from 'react-router'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

// const { classes, onClose, selectedValue, ...other } = this.props;
import './App.css';


class NavBar2 extends Component {

  render() {
    const {navItems} = this.props;

    return (
      <div>
        <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
          <h5 class="my-0 mr-md-auto font-weight-normal">Company name</h5>
        <nav class="my-2 my-md-0 mr-md-3">

        <ul>
          <li>
            <Link to="/page1">Netflix</Link>
          </li>
          <li>
            <Link to="/page2">Zillow Group</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
      
        {
                navItems.map(item => (
                    <a class="p-2 text-dark" key={item.link} href={item.link}>{item.label}</a>
                ))
              }
        </nav>

        <a class="btn btn-outline-primary" href="/sign">Sign up</a>
        </div>


       <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center" className="Top-contanier">
        <h1 class="display-4">Pricing-2</h1>
          <p class="lead">Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components and utilities with little customization.</p>
       </div>




      </div>


      

    );
  }
}

export default NavBar2;
