import React, { Component } from 'react';
import { postCmd } from '../services/service1.js';

import util from 'util';

class CommentDialog extends Component {

  state = {
      show: false,
  };  

  handleClose() {
    this.setState({ show: false });
  }


  handlePost = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    let cmd =  event.target.cmd.value
    let message =  event.target.message.value
    
    console.log('post:' + util.inspect(data));

    postCmd('app.w100.comment', {
      title: 'ZZ-z',
      subTitle: 'A sub title',
      userName: 'Nisse',
      body: message
    });

//    this.setState({ show: false });
  }

  
  /* code from AwsSite102 */
  /* https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f */
  
  
  render() {
    const {navItems, translation} = this.props;

    return (

<div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">{translation.title}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="container-fluid bg-light py-3">
        {/*}
        <!--form action="https://api.whazonk.com/cmd" method="post" target="/" -->
        <!-- form  onsubmit="postFunction();" -->
        <!-- form action="https://api.whazonk.com/cmd" method="post" onsubmit="return closeSelf(this);" -->

        <form id="z100" action="javascript:postFunction();">      
         */ }
        <form id="z100" onSubmit={this.handlePost}>      
          <input type="hidden" id="form2" name="id" value="Z-101" />
          <input type="hidden" id="form3" name="app" value="hemstatus" />
          <input type="hidden" id="form4" name="cmd" value="add_comment" />

          <div class="messages"></div>

          <div class="controls">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
        <label for="form_message">{translation.commentLabel}</label>
        <textarea id="form_message" name="message" class="form-control" placeholder={translation.text1}rows="4" required="required" data-error="send a message."></textarea>
                  <div class="help-block with-errors"></div>
                </div>
              </div>

            </div>
          </div>
          <div class="clearfix"></div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
        <label for="form_email">{translation.emailLabel}</label>
        <input id="form_email" type="email" name="email" class="form-control" placeholder={translation.email} required="required" data-error={translation.email_err} />
                <div class="help-block with-errors"></div>
              </div>
            </div>

            <div class="col-md-12">
        <input type="submit" class="btn btn-sm btn-outline-secondary btn-margin" value={translation.postLabel} />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
        <p class="text-muted"><strong>*</strong>{translation.text2}</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

      
    );
  }
}


CommentDialog.defaultProps = {
  title: 'Page Title',

  translation: {
    title: 'Comment',
    emailLabel: 'Email *',
    email: 'Please enter your email *',
    email_err:  'Valid email is required.',
    postLabel: 'Post comment',
    commentLabel: 'Message *',
    text1: 'Make a comment *',
    text2: 'These fields are required.'
  }
  
};



export default CommentDialog;

