import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'

import rootReducer from './reducers'

import App from './App';
import App2 from './nav2/App2';

import * as serviceWorker from './serviceWorker';


import { fetchAtten } from './actions/attendActions'

import {
  FETCH_ATTEND_REQ,
  FETCH_ATTEND_SUCCESS,
} from './actions/actionTypes'


const store = createStore(rootReducer,
                          {
                            todos: [
                              {id: '0', completed: true, text: 'item-1', dis: false },
                              {id: '1', completed: false, text: 'item-2', dis: true },
                              {id: '2', completed: true, text: 'item-3', dis: false },
                            ],
                            length: 3,
                            attends: [
                              {id: '0', completed: true, text: 'att-1', dis: false },
                              {id: '1', completed: false, text: 'att-2', dis: true },
                              {id: '2', completed: true, text: 'att-3', dis: false },
                              {id: '3', completed: true, text: 'att-4', dis: false },
                            ],
                            groups: [
                              { id: "x1", label: 'group-1' },
                              { id: "x2", label: 'group-2' },
                              { id: "x3", label: 'group-3' },
                              { id: "x4", label: 'group-4' },
                            ],
                            selectedGroupId: 'x3',
                          },

                          applyMiddleware(
                            logger, stuff2)
                          
                         )



const received = (type, payload) => ({
  type,
  payload
});



function logger({ getState, dispatch }) {
  //const logger = store => next => action => {
//const logger = store => next => action => {
  
  return next => action => {
    console.log('will dispatch', action)


    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

    console.log('state after dispatch', store.getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}

//-----------------

//const stuff2 = store => next => action => {
//function stuff2({ store, next, action }) {
function stuff2({ getState, dispatch, next }) {

  return next => action => {

  //-----------------------
    if (action.type == 'FETCH_ATTEND_REQ') {
      const {id } = action;
      console.log('Z12:' + id)
      
//      fetchAtten(id, dispatch)

//      setTimeout( (id) => {
      console.log('Z13:' + id)

      var data = []
      switch(id) {
      case "x3":
        data = [
          {id: '0', completed: true, text: 'att-3-1', dis: false },
          {id: '1', completed: false, text: 'att-3-2', dis: true },
          {id: '2', completed: false, text: 'att-3-3', dis: false },
          {id: '3', completed: false, text: 'att-3-4', dis: false },
          {id: '4', completed: true, text: 'att-3-5', dis: false },
        ]          
        break
      case "x4":
        data = [
          {id: '0', completed: true, text: 'att-4-1', dis: false },
          {id: '1', completed: false, text: 'att-4-2', dis: true },
          {id: '2', completed: false, text: 'att-4-3', dis: false },
          {id: '3', completed: false, text: 'att-4-4', dis: false },
          {id: '4', completed: true, text: 'att-4-5', dis: false },
        ]          

        break;
      }
      store.dispatch(received('FETCH_ATTEND_SUCCESS', data))
    }
    
//    , 1000)
//    }

  
    //------------------------------
    


  



  let result = next(action)
//    localStorage['redux-store'] = JSON.stringify(store.getState())
    return result

  }

}
  
/*
const apiMiddleware = ({ dispatch }) => next => action => {

  return next => action => {
    console.log('** Api middle')
    
    if (action.type !== FETCH_ATTEND_REQ) return;

    const {id } = action.payload;
    
    fetchAtten(id)
  }
}
*/

//-----------------




store.subscribe(() =>
                console.log(store.getState()))

ReactDOM.render(
    <Provider store={store}>
      <App2 />
    </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
