
import util from 'util';

const selectedGroupId = (state, action) => {

  switch (action.type) {
  case 'SELECT_GROUP':
    console.log('Select:' + util.inspect(action));
        
    return action.id;
    
    case 'SET_VISIBILITY_FILTER':
    return action.filter
    
    default:
//    return state
    return 'x1';
  }

  
}

export default selectedGroupId
