import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ListGroup
  } from 'react-bootstrap';

import Attend from './Attend'

const AttendList = ({ attends, toggleAttend, sendAttend }) => (
  <div>
    <ListGroup>
    {attends.map(attend => (
      <Attend key={attend.id} {...attend} onClick={() => toggleAttend(attend.id)} />
    ))}
  </ListGroup>
    <Button variant="outline-secondary" onClick={() => sendAttend('1')} >Send</Button>
  </div>
)

AttendList.propTypes = {
  attends: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      completed: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  toggleAttend: PropTypes.func.isRequired,
  sendAttend: PropTypes.func.isRequired,
}

export default AttendList
