import React, { Component } from 'react';

import util from 'util';

//import AddTodo from '../containers/AddTodo'
import VisibleAttendList from '../containers/VisibleAttendList'
import GroupSelectView from '../containers/GroupSelectView'

import {
  Dropdown,
  Card,
  Button,
  ListGroup,
  Badge,
  ButtonToolbar,
  Form
  
} from 'react-bootstrap';

class Content6 extends Component {

  state = {
    xid: 'x1'
  }

  onSelect = event => {
    let target = event.target;
    console.log('' + util.inspect(event))
  }

  onSelect2 = event => {
    let target = event.target;
    console.log('' + util.inspect(event.target.value))
    this.setState({xid: event.target.value})
  }
/*
  componentWillMount() {
    const { fetchCoins } = this.props;
    // Fetching coins action.
    fetchCoins();
  }
*/
  
  
  render() {
    return (
      <div>

        <Card>
        <Card.Header>
                <Form>
        <div class="row">

        <div class="col-5">
           <GroupSelectView />
        
        {/*         
          <Form.Group controlId={this.xid}>
            <Form.Control as="select" value={this.state.xid} onChange={this.onSelect2}>
              <option></option>
              <option value="x1">MA 7A</option>
              <option value="x2">FY8B</option>
              <option value="x3">3</option>
              <option value="x4">4</option>
            </Form.Control>
          </Form.Group>
         */ }
      
          </div>

      
            <div class="col-4">
              NO7A 09:12
            </div>


      {/* 
        <div class="col-2">

      
        <Dropdown  onSelect={this.onSelect}>
        <Dropdown.Toggle variant="outline-success" id="dropdown-basic" >
                  Filter
               </Dropdown.Toggle>

               <Dropdown.Menu>
                 <Dropdown.Item eventKey="zx1">Action</Dropdown.Item>
                 <Dropdown.Item active="true" eventKey="zx2">Another action</Dropdown.Item>
                 <Dropdown.Item id="action-3" eventKey="x3">Something else</Dropdown.Item>
               </Dropdown.Menu>
             </Dropdown>
           </div>
       */}
      
        <div class="col-1">
          <i class="fas fa-ellipsis-h"></i>
        </div>

              </div>
            </Form>



      </Card.Header>
            <Card.Body>
              <VisibleAttendList />
            </Card.Body>
          </Card>
      </div>
    )
  }
}


export default Content6;
