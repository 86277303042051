import React, { Component } from 'react';

import CommentDialog from './CommentDialog';
import LoginDialog from './LoginDialog';


class Header extends Component {

/*
  handleShow() {
    var divDOMNode = ReactDOM.findDOMNode("#commentModal");
    divDOMNode.show(true);
    this.setState({ show: true });
  }
*/
  
  render() {
    const {navItems, title} = this.props;

    return (

        <header className="main-header py-3">
        <div className="row flex-nowrap justify-content-between align-items-center">
          <div className="col-4 pt-1">
            <a className="text-muted" href="#">Subscribe</a>
          </div>
          <div className="col-4 text-center">
        <a className="blog-header-logo text-dark" href="#">{title}</a>
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center">

      {/* 
        <a class="text-muted" href="#">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mx-3"><circle cx="10.5" cy="10.5" r="7.5"></circle><line x1="21" y1="21" x2="15.8" y2="15.8"></line></svg>
        </a>
*/}


      
            <button type="button" className="btn btn-sm btn-noborder btn-margin" id="zappo" data-toggle="modal" data-target="#commentModal">
              <i className="far fa-comment"></i>
            </button>

            <button type="button" className="btn btn-sm btn-noborder btn-margin" id="zappo" data-toggle="tooltip" data-placement="bottom" title="Settings" >
              <i className="fas fa-cog"></i>
            </button>

            <button type="button" className="btn btn-sm btn-noborder btn-margin" id="login" data-toggle="modal" data-target="#loginDialog"><i class="fas fa-sign-in-alt" /></button>
      
          </div>
        </div>



        <CommentDialog />
        <LoginDialog />
        
      </header>
      
      
    );
  }
}


Header.defaultProps = {
  title: 'Page Title'
};


export default Header;
