import React, { Component } from 'react';

import {Link} from 'react-router-dom';


//import { graphql } from "react-apollo";
import gql from "graphql-tag";
const util = require('util');


// Set up a subscription query
const subquery = gql(`
  subscription Sub {
    onUpdateChangeLogDB {
      project
      date
      note
      ref
    }
}`);


const fetchquery = gql(`
  query {
    getChangeLogDB(project:"proj-5", date: "2018-12-26T00:13:42.822Z") {
      project
      date
      note
      ref
	  }
}`);






class Content2 extends Component {

  state = {
    gdata: [],
  }

  
  componentDidMount = () => {

//    this.getItemList();

    return;

    const { client } = this.props;

    //    this.getItemList();
    //    this.subscription = this.props.subscribeToComments();

    client.query({
      query: fetchquery
//    }).then(({ data: { listTodos } }) => {
    }).then(res => {
      console.log(util.inspect(res.data.getChangeLogDB))
      //      console.log(listTodos.items);

      this.setState({ docs: [res.data.getChangeLogDB] });
    });


    const observable = client.subscribe({ query: subquery });

//    this.setState({observable: observable});

    const realtimeResults = data =>  {
      console.log('update data: ', util.inspect(data));

      this.setState({ docs: [data.data.onUpdateChangeLogDB] });

    };

    this.subscription = observable.subscribe({
      next: realtimeResults,
      complete: console.log,
      error: console.log,
    });

//    this.setState({subscription: subscription});

  }




  getItemList = () => {
    fetch('https://api.whazonk.com/cmd',
          {
            method: "GET",
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin",
            
            redirect: "follow",
            referrer: "no-referrer",

            headers: {
              "Content-Type": "application/json; charset=utf-8",
            }
          }
         )
      .then((response) => {


        return response.json();
      })
      .then((myJson) => {
  //      let zz = JSON.parse(myJson);
        console.log(JSON.stringify(myJson));

       this.setState({gdata: myJson});
      })
    ;

  }



  
  render() {
    const {navItems} = this.props;
    const {gdata} = this.state;
    
    return (
        <div>

        <div class="list-group">

      {
        gdata.map((item) => (

          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start ">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{item.title}</h5>
            <small>{item.Timestamp}</small>
          </div>
            <p class="mb-1">{item.body}</p>
          <small>Donec id elit non mi porta.</small>
        </a>

                 )
      )       
      }
      

      </div>
        
</div>      
    );
  }
}

export default Content2;
