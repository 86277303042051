import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import './App.css';


import {
  Card,
  Button,
  ListGroup,
  Badge
} from 'react-bootstrap';



const contentItems = [
  {
    topic: 'topic-1',
    title: 'Device-1',
    date: '3 Aug',
    summary: "The summary",
    link: '/article/1'
  },
  {
    topic: 'topic-2',
    title: 'Device-2',
    date: '4 Aug',
    summary: "Another description of the stuff",
    link: '/article/2'
  },
  {
    topic: 'topic-3',
    title: 'Device-3',
    date: '23:45',
    summary: "The summary for the 3rd items",
    link: '/article/3'
  }

];



class Page1 extends Component {

  render() {
//    const {navItems} = this.props;

    return (

        <div class="row mb-2">

      {
        contentItems.map(item => (

            <div class="col-md-6 " key={item.link}>



          <Card>
            <Card.Header>{item.title}</Card.Header>
              <Card.Body>
                <Card.Title></Card.Title>
                  <Card.Text> {item.summary} </Card.Text>


          </Card.Body>
            <Card.Footer className="text-muted">
              <div class="row">
                <div class="col-1"><i className="far fa-question-circle"></i> </div>
                <div class="col-10"> {item.date}</div>
                <div class="col-1"> <Badge pill variant="success">14</Badge> </div>
            </div>

          </Card.Footer>

          </Card>






          { /*
            <div class="card flex-md-row mb-4 shadow-sm h-md-250 card-shadow">

            <div class="card-body d-flex flex-column align-items-start">
              <Link className="p-2 text-muted " style={{ textDecoration: 'none' }} to={item.link} >
              <strong class="d-inline-block mb-2 text-primary">{item.topic}</strong>
              <h3 class="mb-0">
              <a class="text-dark" href="#">{item.title}</a>
                  </h3>
                  <div class="mb-1 text-muted">{item.date}</div>
                <p class="card-text mb-auto">{item.summary}</p>
              </Link>

              <div class="card-footer ">
                <button type="button" className="btn btn-sm btn2 btn-margin" id="zappo"  data-toggle="modal" data-target="#commentModal">
                  <i class="far fa-edit" />
                </button>

              </div>
            </div>
            </div>

          */ }


            </div>

        ))
      }
      </div>



    );
  }
}

export default Page1;
