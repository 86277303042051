import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap-theme.min.css';

import './App.css';


import Header from './Header';
import NavBar from './NavBar';

import NavBar2 from './NavBar2';
import NavLinks from './NavLinks';

import Content1 from './Content1';
import Content2 from './Content2';
import Content3 from './Content3';
import Content4 from './Content4';
import Content5 from './Content5';
import Content6 from './Content6';

import ArticleView from './ArticleView'

import appSyncConfig from "../aws-exports";

import { ApolloProvider } from "react-apollo";
import AWSAppSyncClient, { defaultDataIdFromObject } from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";

/*
  <Content1 />
*/

const client = new AWSAppSyncClient({
  url: appSyncConfig.aws_appsync_graphqlEndpoint,

  region: appSyncConfig.aws_appsync_region,

  auth: {
    type: appSyncConfig.aws_appsync_authenticationType,
    apiKey: appSyncConfig.aws_appsync_apiKey,
  },

  cacheOptions: {
    dataIdFromObject: (obj) => {
      let id = defaultDataIdFromObject(obj);

      if (!id) {
        const { __typename: typename } = obj;
        switch (typename) {
        case 'Comment':
          return `${typename}:${obj.commentId}`;
        default:
          return id;
        }
      }

      return id;
    }
  },

  offlineConfig: {
    callback: (err, succ) => {
      if(err) {
        const { mutation, variables } = err;

        console.warn(`ERROR for ${mutation}`, err);
      } else {
        const { mutation, variables } = succ;

        console.info(`SUCCESS for ${mutation}`, succ);
      }
    },
  }

});



class  App2 extends Component {


  render() {
    return (
        <Router >
        <body class="site-header">
        <div class="container ">

      {/*
         <Header />
         <NavBar />
       */ }

        <NavBar2 />
        <NavLinks />

        <Switch>
          <Route exact path="/" component={Content1} />
          <Route exact path="/page2" render={ (props) => <Content2 {...props} client={client} />} />
          <Route exact path="/page3" render={ (props) => <Content3 {...props} client={client} />} />
          <Route exact path="/page4" render={ (props) => <Content4 {...props} client={client} />} />
        <Route exact path="/page5" render={ (props) => <Content5 {...props} client={client} />} />
        <Route exact path="/page6" render={ (props) => <Content6 {...props} client={client} />} />

          <Route exact path="/article/:articleId" component={ArticleView} />
          <Route component={Error404} />
        </Switch>

      </div>
        </body>
        </Router>
    )
  }
}


function Error404() {
  return (
    <div>
      <h2>404</h2>
    </div>
  );
}


App2.defaultProps = {
//  client: client,
}


export default App2;
