import React from 'react'
import PropTypes from 'prop-types'
import {
  ListGroup
  } from 'react-bootstrap';

const Attend = ({ onClick, completed, text, dis }) => (
    
    <ListGroup.Item onClick={onClick} disabled={dis} >
    <div class="row">
      <div class="col-1">
        { completed ? <i className="far fa-check-circle"></i> : <i className="far fa-circle"></i> }
      </div>
      <div class="col-11">
        {text}
      </div>
    </div>
  </ListGroup.Item>
)

Attend.propTypes = {
  onClick: PropTypes.func.isRequired,
  completed: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
}

export default Attend
