import React from 'react'
import PropTypes from 'prop-types'
import {
  ListGroup,
  Form
  } from 'react-bootstrap';

//import Attend from './Attend'

const GroupSelect = ({ groups, selectedGroupId, selectGroup }) => (
    <Form.Group >
    <Form.Control as="select" value={selectedGroupId}
      onChange={(event) => {
        selectGroup(event.target.value)
      }
     }
    >
        <option></option>
        {groups.map(group => (
            <option key={group.id} value={group.id}>{group.label}</option>
        ))}
      </Form.Control>

    </Form.Group>

)

/*
    <ListGroup>
    {attends.map(attend => (
      <Attend key={attend.id} {...attend} onClick={() => toggleAttend(attend.id)} />
    ))}
    </ListGroup>

  */


GroupSelect.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      completed: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  selectGroup: PropTypes.func.isRequired
}

export default GroupSelect
