
import {
  FETCH_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_ERROR
} from '../actions/actionTypes'

// import { getNewState } from '../shared/utils/frontend';



const getNewState = (state, newState) => {
  return Object.assign({}, state, newState);
}



const groups = (state = [], action) => {
  switch (action.type) {

  case FETCH_GROUP_SUCCESS:
    
    const { payload: groups } = action;

    return getNewState(state, {
      groups
    });

    
    
    
/*
      return state.map(group =>
        group.id === action.id ? { ...group.selectedGroupId, completed: !group.completed } : group
                      )
    // selectedGroupId
    
  case 'TOGGLE_ATTEND':
      return state.map(group =>
        group.id === action.id ? { ...group, completed: !group.completed } : group
                      )
*/

    
    
    default:
      return state
  }
}

export default groups
