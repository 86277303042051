import React, { Component } from 'react';
import gql from "graphql-tag";

import util from 'util';

import CommenstList from './CommentsList';


/*
const fetchquery = gql(`
  query {
    getChangeLogDB(project:"proj-5", date: "2018-12-26T00:13:42.822Z") {
      project
      date
      note
      ref
	  }
}`);
*/

const fetchquery = gql(`
  query {
    listComments {
      items {
    date
    userId
    comment
}
  }
}`);




const optionItems = [
  {val: "proj-1", label: 'Label-1'},
  {val: "proj-2", label: 'Label-2'},
  {val: "proj-3", label: 'Label-3'},
  {val: "proj-4", label: 'Label-4'},
  {val: "proj-5", label: 'Label-5'},
  
]


class Content3 extends Component {
  state = {
    xstate: false,
    startTime: null,
    endTime: null,
    selectedProj: 'proj-2',

    comments: [],

    xstate1: true,
  }

  punchIn = (event) => {
    let target = event.target;
    
    console.log('In:' + util.inspect(target));

    this.setState({xstate: true });
    this.setState({startTime: new Date() });

    // new Date().toISOString().slice(11, 16);
    
  }
  
  punchOut = (event) => {
    console.log('Out');
    this.setState({xstate: false });
    this.setState({startTime: null });
  }
  
  onChange = (event) => {
    let target = event.target;
    console.log('val:' + target.selectedIndex)
    console.log('val2:' + target.value)
//    console.log( util.inspect(target));
  }



  getComments = () => {
    const { client } = this.props;

    client.query({
      query: fetchquery
    }).then(res => {
      console.log(util.inspect(res.data.listComments.items))

      this.setState({ comments: res.data.listComments.items });
    });

  }


  onToggle = (event) => {
    event.preventDefault()
    let target = event.target;

    this.setState({xstate1: !this.state.xstate1})
  }
  
  
  componentDidMount = () => {
    this.getComments();
  }

  
  render() {
    const {navItems} = this.props;
    const {xstate, comments} = this.state;
  
    return (

        <div class="container">

        <div class="row">
        <div class="col-8">{this.state.startTime ?  this.state.startTime.toISOString().slice(11, 16) : '' }</div>
        <div class="col-4"> <input type="text" class="form-control" aria-label="Text input with segmented dropdown button" value="Zappa Dappa" readOnly /></div>
        </div>


        <div class="row">
        <div class="col-8">col-8</div>
        <div class="col-4"> {this.state.xstate ? 'IN' : 'OUT'} </div>
        </div>


        <div class="row">
        <div class="col-8">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">Project</label>
        </div>
        
        <select class="custom-select" id="inputGroupSelect01" onChange={this.onChange} value={this.state.selectedProj}>
          <option selected>Choose...</option>
          {
            optionItems.map(item => (
                <option value={item.val}>{item.label}</option>
            )
                           )}
            </select>
          </div>


      </div>
        <div class="col-4"></div>
        </div>



        <div class="row">
          <div class="col"></div>
        <div class="col"><button type="button" id="pIn" class="btn btn-outline-secondary shadow " onClick={this.punchIn } disabled={xstate} >Punch-IN</button></div>
          <div class="col"></div> 
        <div class="col"><button type="button" id="pOut" class="btn btn-outline-success shadow " onClick={this.punchOut}  disabled={!xstate} >Punch-OUT</button></div>
          <div class="col"></div>
        </div>

<hr/>


<div class="container-fluid ">
  <div class="list-group">

    <div class="list-group-item list-group-item-action flex-column align-items-start shadow-sm mt-2">

        <div class="row">
        <div class="col-2"> 12:23 </div>
        <div class="col-6"> project </div>
        <div class="col-2"> co2 </div>
        <div class="col-2">

        <button type="button" className="btn btn-sm btn2 btn-margin" id="zappo"  onClick={this.getComments}>
              <i className="far fa-comment"></i>
            </button>

            <button type="button" className="btn btn-sm btn2 btn-margin" id="zappo"  data-toggle="modal" data-target="#commentModal">
              <i className="far fa-edit"></i>
            </button>

        </div>
        </div>

        <div class="row">
        <div class="col-4">19:35 </div>
        <div class="col-4"> co5 </div>
        <div class="col-4"> co4 </div>
        </div>


</div>

<div class="list-group-item list-group-item-action flex-column align-items-start shadow-sm mt-2">

        <div class="row">

        <div class="col-8">
row2
        </div>
        <div class="col-4">
co2
        </div>
</div>


</div>
</div>


</div>

<hr/>


<hr />
              <i className="far fa-square"></i>
              <i className="far fa-check-square"></i>
              <i className="far fa-plus-square"></i>

              <i className="far fa-circle"></i>
              <i className="far fa-times-circle"></i>
              <i className="far fa-question-circle"></i>
              <i className="far fa-check-circle"></i>
              <i className="fas fa-plus-circle"></i>



            <button type="button" className="btn btn-sm btn-margin btn2 " id="zappo" name="xcircle" onClick={this.onToggle}>
               { this.state.xstate1 ? <i className="far fa-check-circle"></i> : <i className="far fa-circle"></i> }
            </button>

            <button type="button" className="btn btn-sm btn-margin btn2 " id="zappo10" name="z10" onClick={this.onToggle}>
               <i className={ this.state.xstate1 ?  "far fa-check-circle" : "far fa-circle"} ></i>
            </button>

            <button type="button" className="btn btn-sm btn-margin btn2 " id="zappo11" name="z11" onClick={this.onToggle}>
               <i className={ this.state.xstate1 ?  "far fa-question-circle" : "far fa-circle"} ></i>
            </button>

<hr />

        <CommenstList comments={comments} />

      </div>
        
    );
  }
}

export default Content3;
