import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import util from 'util';

class ArticleView extends Component {

  render() {
    const {args} = this.props;
    const {params} = this.props.match;
    
    let id = params.articleId;
    
//    console.log('ArticleProps:' + util.inspect(this.props))
    
    return (
<div>
        <p>ArticleView: {id}</p>      

        <Link class="p-2 text-muted" to="/">Back</Link>
</div>      
    );
  }
}

export default ArticleView;
