

import {
  FETCH_ATTEND_REQ,
  FETCH_ATTEND,
  FETCH_ATTEND_SUCCESS,
  FETCH_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_ERROR
} from './actionTypes'


let nextTodoId = 0
export const addTodo = text => ({
  type: 'ADD_TODO',
  id: nextTodoId++,
  text
})

export const setVisibilityFilter = filter => ({
  type: 'SET_VISIBILITY_FILTER',
  filter
})

export const toggleTodo = id => ({
  type: 'TOGGLE_TODO',
  id
})

export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE'
}

export const toggleAttend = id => ({
  type: 'TOGGLE_ATTEND',
  id
})

export const selectGroup = id => ({
  type: 'SELECT_GROUP',
  id
})

export const groupUpdated = id => ({
  type: 'GROUP_UPDATED',
  id
})


export const fetchGroup = id => ({
  type: FETCH_GROUP,
  id
})
export const fetchGroupSuccess = id => ({
  type: FETCH_GROUP_SUCCESS,
  id
})
export const fetchGroupError = id => ({
  type: FETCH_GROUP_ERROR,
  id
})

export const fetchAttens = id => ({
  type: 'FETCH_ATTEND_REQ',
  id
})


export const sendAttend = id => ({
  type: 'SEND_ATTEND',
  id
})



//---------------------------------------------------

const received = (type, payload) => ({
  type,
  payload
});

const request = (type, id) => ({
  type: type,
  id
});


export const fetchAtten = (id) => dispatch => {
// Dispatching our request action
//  dispatch(request(FETCH_COINS_REQUEST));

  console.log('Zapp:' + id );
  
//  dispatch(request(FETCH_ATTEND_REQ, id));
    


  setTimeout( () => {
   dispatch(received(FETCH_ATTEND_SUCCESS,
                    [
                      {id: '0', completed: true, text: 'att-3-1', dis: false },
                      {id: '1', completed: false, text: 'att-3-2', dis: true },
                      {id: '2', completed: false, text: 'att-3-3', dis: false },
                      {id: '3', completed: false, text: 'att-3-4', dis: false },
                      {id: '4', completed: true, text: 'att-3-5', dis: false },
                    ]
                   ))
  
    
  },
              1000
            )


  
/*

return axios(axiosData)
.then(response => dispatch(received(FETCH_COINS_SUCCESS,
response.data)))
.catch(err => {
// eslint-disable-next-line no-console
console.log('AXIOS ERROR:', err.response);
dispatch(error(FETCH_COINS_ERROR));
})
  */
  
}

