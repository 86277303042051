import React, { Component } from 'react';

import {Link} from 'react-router-dom';

import {
  Nav,
  Alert
} from 'react-bootstrap';


class NavLinks extends Component {
  
  state = {
    menu1: true,
    menu2: true,
    
    alert: {
      show: false,
      headline: 'Something strange did occur',
      message: 'Fix it and redo'
    }
  }


  showAlert = (headline, message) => {
    this.setState({
      alert: {
        show: true,
        headline: 'Something strange did occur',
        message: 'Fix it and redo'
      }
    })
  }

  
  render() {
    const {navItems} = this.props;

    const alertClosed = () => {
      console.log('**closed');
      this.setState({alert: {show: false }})
    }


    return (
      <div>
        <Nav defaultActiveKey="/home" className="flex-row"  className="d-flex justify-content-between">
          { this.state.menu1 && <Link class="p-2 text-muted" to="/">Home</Link> }
          { this.state.menu2 && <Link class="p-2 text-muted" to="/page2">Stuff</Link> }
          { this.state.menu2 && <Link class="p-2 text-muted" to="/page3">Technology</Link> }
        <Link class="p-2 text-muted" to="/page4">Page-4</Link>
        <Link class="p-2 text-muted" to="/page5">Page-5</Link>
        <Link class="p-2 text-muted" to="/page6">Page-6</Link>
      </Nav>

      <Alert dismissible variant="danger" show={this.state.alert.show} onClose={alertClosed}>
        <Alert.Heading>{this.state.alert.headline}</Alert.Heading>
        <p>
          {this.state.alert.message}
        </p>
      </Alert>

</div>      
    );
  }
}


/*
  <div class="nav-barx nav-scroller py-1 mb-2 ">
  <nav class="nav d-flex justify-content-between">

  { this.state.menu1 && <Link class="p-2 text-muted" to="/">Home</Link> }
  { this.state.menu2 && <Link class="p-2 text-muted" to="/page2">Technology</Link> }
  <Link class="p-2 text-muted" to="/page3">More stuff</Link>
        
  </nav>
  </div>

  */



export default NavLinks;
