import React, { Component } from 'react';

class Page1 extends Component {

  render() {
    const {navItems} = this.props;

    return (
        <p>Page 2 </p>
      
    );
  }
}

export default Page1;
