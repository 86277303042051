import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";


import NavBar from './NavBar2';

import App0 from './App0';
import Page1 from './Page1';
import Page2 from './Page2';

import Footer from './Footer';


const navItems = [
  { label: 'Features', link: '#' },
  { label: 'item2', link: '/page1' },
  { label: 'item3', link: '/page2' },
  { label: 'Stuff', link: '/x2' },
]



//   <Router history={browserHistory}>


//


class App extends Component {
  render() {
    return (
        <Router>
        <div className="App">

        <NavBar navItems={navItems}/>
        
        <ul>
          <li>
            <Link to="/page1">P1</Link>
          </li>
          <li>
            <Link to="/page2">P2</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="sign">Sign</Link>
          </li>
        </ul>


      <Switch>
        <Route exact path="/"  component={App0} />
        <Route exact path="/page1" component={Page1} />
        <Route exact path="/page2" component={Page2} />

        <Route exact path="/sign" component={Sign} />
        <Route exact path="/about" component={About} />
        <Route component={Error404} />
        </Switch>
        
        {/*
        <Footer />
         */}
      
        </div>
        
      </Router>

      
    );
  }
}


function Sign() {
  return (
    <div>
      <h2>Sign</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Error404() {
  return (
    <div>
      <h2>404</h2>
    </div>
  );
}

export default App;
