import React, { Component } from 'react';

import NavBar from './NavBar2';

import Footer from './Footer';

const navItems = [
  { label: 'Features', link: '#' },
  { label: 'item2', link: '/page1' },
  { label: 'item3', link: '/page2' },
  { label: 'Stuff', link: '/x2' },
]


//          <NavBar navItems={navItems}/>

class  App0 extends Component {
  render() {
    return (
<div>        

App-0
        
      {/* 
        <header className="App-header">
        </header>
         */}
      
      </div>
  
    )
  }
}

export default App0;

