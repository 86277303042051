import React, { Component } from 'react';

import util from 'util';

import AddTodo from '../containers/AddTodo'
import VisibleTodoList from '../containers/VisibleTodoList'

import {
  Card,
  Button,
  ListGroup,
  Badge,
  ButtonToolbar
} from 'react-bootstrap';


class Content5 extends Component {

  render() {
    return (
      <div>
        <AddTodo />
        <VisibleTodoList />
      </div>
    )
  }
}


export default Content5;
