import React, { Component } from 'react';

import {Link} from 'react-router-dom';



class Page1 extends Component {
  state = {
    menu1: true,
    menu2: true,
    
  }
  
  render() {
    const {navItems} = this.props;
    return (

        <div class="nav-barx nav-scroller py-1 mb-2 ">
        <nav class="nav d-flex justify-content-between">

      { this.state.menu1 && <Link class="p-2 text-muted" to="/">Home</Link> }
      { this.state.menu2 && <Link class="p-2 text-muted" to="/page2">Technology</Link> }
        <Link class="p-2 text-muted" to="/page3">More stuff</Link>
        

      
        <a class="p-2 text-muted" href="#">U.S.</a>
          <a class="p-2 text-muted" href="#">Technology</a>
          <a class="p-2 text-muted" href="#">Design</a>
          <a class="p-2 text-muted" href="#">Culture</a>
          <a class="p-2 text-muted" href="#">Business</a>
          <a class="p-2 text-muted" href="#">Politics</a>
          <a class="p-2 text-muted" href="#">Opinion</a>
        </nav>
      </div>
    );
  }
}

export default Page1;
