import React, { Component } from 'react';

// import gql from "graphql-tag";

import util from 'util';


import {
  Card,
  Button,
  ListGroup,
  Badge,
  ButtonToolbar
} from 'react-bootstrap';


import ContentViewModal from './ContentViewModal';


// import CommenstList from './CommentsList';

/*
const fetchquery = gql(`
  query {
    getChangeLogDB(project:"proj-5", date: "2018-12-26T00:13:42.822Z") {
      project
      date
      note
      ref
	  }
}`);
*/

/*
const fetchquery = gql(`
  query {
    listComments {
      items {
    date
    userId
    comment
}
  }
}`);
*/


const contentItems = [
  {
    id: 'x1',
    label: 'Item-1',
    icon: 'far fa-question-circle',
    cnt: '2'
  },
  {
    id: 'x2',
    label: 'Item-2',
    icon: 'far fa-circle',
    cnt: '1'
  },
  {
    id: 'x3',
    label: 'Item-3',
    icon: 'far fa-circle',
    cnt: null
  }
  
];


class Content4 extends Component {
  state = {
    xstate: false,
    startTime: null,
    endTime: null,
    selectedProj: 'proj-2',

    comments: [],

    xstate1: true,
  }


  constructor(...args) {
    super(...args);

    this.state = {
      modalShow: false,
      xdata: {title: ''}
    };
  }

  
  xclick = (event) => {
//    event.preventDefault()

    console.log('id:' + event.currentTarget.id + ' name:' +event.currentTarget.name);

    this.setState({
      modalShow: true,
      xdata: {
        title: '** The title-' + event.currentTarget.name,
        content: 'The content of the box.'
      }});
    
//    this.setState({ modalShow: true })
  }
  
  componentDidMount = () => {
//    this.getComments();
  }


  render() {
    const {navItems} = this.props;
    const {xstate, comments} = this.state;

    let modalClose = () => this.setState({ modalShow: false });
    
    return (
        <div>

        <ListGroup>
      {
        contentItems.map(item => (

            <ListGroup.Item action tag="button" onClick={this.xclick} key={item.id} name={item.id} id={item.id}>

            <div class="row">
              <div class="col-1"><i className={item.icon}></i> </div>
              <div class="col-10"> {item.label} </div>
            <div class="col-1"> { item.cnt && <Badge pill variant="success">{item.cnt}</Badge>} </div>
            </div>
            
          </ListGroup.Item>
            
        ))
      }

      </ListGroup>

      
        <Card>
            <Card.Header>Featured</Card.Header>
              <Card.Body>
                <Card.Title>Zappa dappa</Card.Title>
                  <Card.Text>
                     With supporting text below as a natural lead-in to additional content.
                  </Card.Text>
                  <Card.Text>
                     With supporting text below as a natural lead-in to <b>additional</b> content.
                  </Card.Text>

                 <Button variant="outline-secondary">Secondary</Button>
                 <Button variant="outline-secondary">Secondary</Button>
                 <Button type='checkbox'>Secondary</Button>

              </Card.Body>
        </Card>


        <Card>
          <Card.Header>Quote</Card.Header>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p>
                {' '}
                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                erat a ante.{' '}
                </p>
              <footer className="blockquote-footer">
                Someone famous in <cite title="Source Title">Source Title</cite>
              </footer>
            </blockquote>
          </Card.Body>
          <Card.Footer className="text-muted">2 days ago</Card.Footer>
      </Card>

      
      <ButtonToolbar>
        <Button
          variant="outline-primary"
          onClick={() => this.setState({ modalShow: true })}
        >
          Launch vertically centered modal
        </Button>

        <ContentViewModal
          show={this.state.modalShow}
          xdata= {this.state.xdata}
          onHide={modalClose}
        />
      </ButtonToolbar>

      </div>
    );
  }
}


export default Content4;
