import React, { Component } from 'react';

import CommentDialog from './CommentDialog';
import LoginDialog from './LoginDialog';

import {Link} from 'react-router-dom';


import {
  Nav,
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button
} from 'react-bootstrap';



import LoginReactModal from './LoginReactModal';


class NavBar2 extends Component {
  state = {
    menu1: true,
    menu2: true,

  }

  render() {
    const {navItems, title} = this.props;

    return (

        <Navbar className="mainHeader" bg="light" expand="lg">
          <Navbar.Brand href="#home">X-Zapp</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>

            { this.state.menu1 && <Link class="p-2 text-muted" to="/">Home</Link> }
            { this.state.menu2 && <Link class="p-2 text-muted" to="/page3">Technology</Link> }


          </Nav>

        <Button className="btn2"> <i className="far fa-comment"></i></Button>
        <Button className="btn2"> <i className="fas fa-cog"></i></Button>
        <Button className="btn2"> <i class="fas fa-sign-in-alt" /> </Button>

      </Navbar.Collapse>

{/*
     <LoginReactModal />
*/}

    </Navbar>

    );
  }
}


/*
      <NavDropdown title="Dropdown" id="basic-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown>

  */


NavBar2.defaultProps = {
  title: 'Page Title'
};


export default NavBar2;
