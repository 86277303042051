import { combineReducers } from 'redux'
import todos from './todos'
import visibilityFilter from './visibilityFilter'
import attends from './attends'
import groups from './groups'
import selectedGroupId from './selectedGroupId'

export default combineReducers({
  todos,
  visibilityFilter,
  attends,
  groups,
  selectedGroupId
})
