
import {
  FETCH_ATTEND,
  FETCH_ATTEND_SUCCESS,
  FETCH_ATTEND_ERROR
} from '../actions/actionTypes'


const attends = (state = [], action) => {
  switch (action.type) {

  case 'FETCH_ATTEND_SUCCESS':
    return action.payload
    
  case 'SELECT_GROUP':

    let z2 = [
      {id: '0', completed: true, text: 'att-2-1', dis: false },
      {id: '1', completed: false, text: 'att-2-2', dis: true },
      {id: '2', completed: false, text: 'att-2-3', dis: false },
      {id: '3', completed: false, text: 'att-2-4', dis: false },
      {id: '4', completed: true, text: 'att-2-5', dis: false },
    ]
    
    return z2;
    
    case 'TOGGLE_ATTEND':
      return state.map(attend =>
        attend.id === action.id ? { ...attend, completed: !attend.completed } : attend
      )
    default:
      return state
  }
}

export default attends
