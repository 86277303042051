import React, { Component } from 'react';

import util from 'util';


class LoginDialog extends Component {

  state = {
      show: false,
  };  

  handleClose() {
    this.setState({ show: false });
  }


  handlePost = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.target);

//    let cmd =  event.target.cmd.value
//    let message =  event.target.message.value
    
    console.log('post:' + util.inspect(data));
//    this.setState({ show: false });

    let z = document.getElementById("loginForm");
    document.getElementById("loginForm").reset();
//    document.getElementById("#loginForm").modal('hide');
    this.handleClose();
  }


  
  /* code from AwsSite102 */
  /* https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f */
  
  
  render() {
    const {navItems} = this.props;

    return (

        <div class="modal fade" id="loginDialog" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">Login</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form id="loginForm" onSubmit={this.handlePost} >
        <div class="form-group">
          <div class="modal-body ">
            <div class="row">
              <div class="col-3">
                <label for="uname"><b>Email</b></label>
              </div>
              <div class="col-9">
                <input type="text" placeholder="Enter email" id="xemail" name="email" required />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="psw"><b>Password</b></label>
              </div>
              <div class="col-9">
                <input type="password" placeholder="Enter Password" id="xpwd" name="pwd" required />
              </div>
            </div>
        </div>
        </div>
        <div class="modal-footer">
          <label class="float-sm-left">
            <input type="checkbox"  checked="checked" name="remember" /> Remember me
          </label>
          <button type="submit" class="btn btn-sm btn-outline-secondary btn-margin">Login</button>
        </div>
      </form>
      
    </div>
  </div>
</div>
      
    );
  }
}

export default LoginDialog;


      
