import { connect } from 'react-redux'

import { selectGroup, fetchAttens, fetchAtten  } from '../actions'

import GroupSelect from '../components/GroupSelect'
import { VisibilityFilters } from '../actions'


import { fetchGroup }  from '../actions/groupActions'
// import { fetchAtten }  from '../actions/attendActions'


const getVisibleGroups = (groups, filter) => {
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return groups
    case VisibilityFilters.SHOW_COMPLETED:
      return groups.filter(t => t.completed)
    case VisibilityFilters.SHOW_ACTIVE:
      return groups.filter(t => !t.completed)
    default:
      throw new Error('Unknown filter: ' + filter)
  }
}

const mapStateToProps = state => ({
  groups: getVisibleGroups(state.groups, state.visibilityFilter),
  selectedGroupId: state.selectedGroupId, 
})

const mapDispatchToProps = dispatch => ({
//  selectGroup: id => dispatch(selectGroup(id)),
  selectGroup: id => dispatch(fetchAttens(id)),
//  selectGroup: id => dispatch(fetchAtten(id)),
  //  selectGroup: id => fetchAtten(id),
  
  fetchGroup: () => dispatch(fetchGroup()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupSelect)
