
import {
  FETCH_ATTEND_REQ,
  FETCH_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_ERROR
} from './actionTypes'


// import { request, received, error } from '../shared/redux/baseActions';


const received = (type, payload) => ({
  type,
  payload
});

const request = type => ({
  type
});


export const fetchGroup = () => dispatch => {
// Dispatching our request action
//  dispatch(request(FETCH_COINS_REQUEST));


  dispatch(received(FETCH_GROUP_SUCCESS,
                    [
                      { id: "x10", label: 'group-11' },
                      { id: "x11", label: 'group-12' },
                      { id: "x12", label: 'group-13' },
                      { id: "x13", label: 'group-14' },
                    ]
                   ))
  

  
/*

return axios(axiosData)
.then(response => dispatch(received(FETCH_COINS_SUCCESS,
response.data)))
.catch(err => {
// eslint-disable-next-line no-console
console.log('AXIOS ERROR:', err.response);
dispatch(error(FETCH_COINS_ERROR));
})
  */


  
}


  
