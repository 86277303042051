
import {
  FETCH_ATTEND_REQ,
  FETCH_ATTEND,
  FETCH_ATTEND_SUCCESS,
  FETCH_ATTEND_ERROR
} from './actionTypes'


// import { request, received, error } from '../shared/redux/baseActions';


const received = (type, payload) => ({
  type: type,
  payload
});

const request = (type, id) => ({
  type: type,
  id
});



export const fetchAtten = (id) => dispatch => {
// Dispatching our request action
//  dispatch(request(FETCH_COINS_REQUEST));

  console.log('--Zapp:' + id );
  
//  dispatch(request(FETCH_ATTEND_REQ, id));
    


  setTimeout( () => {
    dispatch(received(FETCH_ATTEND_SUCCESS,
                      [
                        {id: '0', completed: true, text: 'att-3-1', dis: false },
                        {id: '1', completed: false, text: 'att-3-2', dis: true },
                        {id: '2', completed: false, text: 'att-3-3', dis: false },
                        {id: '3', completed: false, text: 'att-3-4', dis: false },
                        {id: '4', completed: true, text: 'att-3-5', dis: false },
                      ]
                     ))
  },
              1000
            )


  
/*

return axios(axiosData)
.then(response => dispatch(received(FETCH_COINS_SUCCESS,
response.data)))
.catch(err => {
// eslint-disable-next-line no-console
console.log('AXIOS ERROR:', err.response);
dispatch(error(FETCH_COINS_ERROR));
})
  */
  
}


  
